<template>
  <div>
    <div class="h-1 bg-pmb-purple"></div>
    <div class="container py-5">
      <div class="flex items-center justify-between">
        <img
          src="/images/main-logo.png"
          alt="PMB QUICK ACCESS"
          class="w-40 mx-0 md:w-52"
        />
        <div class="text-left">
          <h1 class="text-lg font-semibold text-pmb-purple md:text-xl lg:text-2xl">Call Us</h1>
          <a href="tel:+60386883911" id="call-btn-top">
          <div class="transition duration-200 rounded-lg hover:bg-gray-700 bg-pmb-purple">
            <h1 class="p-1 px-3 text-sm font-semibold text-white lg:text-xl md:text-base">03 8688 3911</h1>
          </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>