<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container">
      <div class="py-40 md:py-44">
        <div class="">
          <h1
            class="pb-5 text-4xl font-bold text-center text-white uppercase txt-shadow md:text-5xl"
          >
            Your One Stop <br> Access
            Solutions Partner
          </h1>
          <a
            v-if="!hideEnquiry"
            href="#"
            id="btn-enquire"
            v-scroll-to="{ el: '#element', duration: 1500 }"
          >
            <div class="p-2 mx-auto text-white transition duration-300 ease-linear border-2 border-white rounded-md hover:bg-white hover:text-gray-900 w-44">
              <h1 class="text-base font-semibold text-center">
                Enquire Us Now
              </h1>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.txt-shadow{
  text-shadow: 2px 2px 2px #000;
}
</style>