<template>
  <div>
    <!-- Tabs -->
    <div id="tabs" class="flex flex-wrap justify-center pt-6 lg:max-w-3xl lg:mx-auto">
      <div class="w-1/2 p-1 md:w-1/5">
        <div
          v-bind:class="[tab === 'tab-1' ? 'active' : '']"
          @click="tab = 'tab-1'"
          class="transition duration-300 ease-linear bg-gray-200 rounded-md cursor-pointer hover:text-white hover:bg-pmb-purple"
        >
          <h1 class="py-2 text-sm font-medium text-center">Starling Mall</h1>
        </div>
      </div>
      <div class="w-1/2 p-1 md:w-1/5">
        <div
          v-bind:class="[tab === 'tab-2' ? 'active' : '']"
          @click="tab = 'tab-2'"
          class="transition duration-300 ease-linear bg-gray-200 rounded-md cursor-pointer hover:text-white hover:bg-pmb-purple"
        >
          <h1 class="py-2 text-sm font-medium text-center">
            Acoris Mont Kiara
          </h1>
        </div>
      </div>
      <div class="w-1/2 p-1 md:w-1/5">
        <div
          v-bind:class="[tab === 'tab-3' ? 'active' : '']"
          @click="tab = 'tab-3'"
          class="transition duration-300 ease-linear bg-gray-200 rounded-md cursor-pointer hover:text-white hover:bg-pmb-purple"
        >
          <h1 class="py-2 text-sm font-medium text-center">Suria KLCC</h1>
        </div>
      </div>
      <div class="w-1/2 p-1 md:w-1/5">
        <div
          v-bind:class="[tab === 'tab-4' ? 'active' : '']"
          @click="tab = 'tab-4'"
          class="transition duration-300 ease-linear bg-gray-200 rounded-md cursor-pointer hover:text-white hover:bg-pmb-purple"
        >
          <h1 class="py-2 text-sm font-medium text-center">Equatorial Hotel</h1>
        </div>
      </div>
      <div class="w-1/2 p-1 md:w-1/5">
        <div
          v-bind:class="[tab === 'tab-5' ? 'active' : '']"
          @click="tab = 'tab-5'"
          class="transition duration-300 ease-linear bg-gray-200 rounded-md cursor-pointer hover:text-white hover:bg-pmb-purple"
        >
          <h1 class="py-2 text-sm font-medium text-center">Star Residence</h1>
        </div>
      </div>
    </div>

    <!-- tabs modal -->
    <div class="pt-6">
      <div v-show="tab == 'tab-1'" class="flex flex-wrap md:justify-center">
        <div v-for="(item,i) in starling_mall" :key="i" class="md:p-1 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="box">
          <img :src="item.image" :alt="item.alt" class="">
          <div class="box-content">
            <h3 class="title">{{item.alt}}</h3>
            <span>Projects</span>
          </div>
        </div>
        </div>
      </div>
      <div v-show="tab == 'tab-2'" class="flex flex-wrap md:justify-center">
        <div v-for="(item,i) in mont_kiara" :key="i" class="md:p-1 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="box">
          <img :src="item.image" :alt="item.alt" class="">
          <div class="box-content">
            <h3 class="title">{{item.alt}}</h3>
            <span>Projects</span>
          </div>
        </div>
        </div>
      </div>
      <div v-show="tab == 'tab-3'" class="flex flex-wrap md:justify-center">
        <div v-for="(item,i) in suria_klcc" :key="i" class="md:p-1 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="box">
          <img :src="item.image" :alt="item.alt" class="">
          <div class="box-content">
            <h3 class="title">{{item.alt}}</h3>
            <span>Projects</span>
          </div>
        </div>
        </div>
      </div>
      <div v-show="tab == 'tab-4'" class="flex flex-wrap md:justify-center">
        <div v-for="(item,i) in equatorial" :key="i" class="md:p-1 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="box">
          <img :src="item.image" :alt="item.alt" class="">
          <div class="box-content">
            <h3 class="title">{{item.alt}}</h3>
            <span>Projects</span>
          </div>
        </div>
        </div>
      </div>
      <div v-show="tab == 'tab-5'" class="flex flex-wrap md:justify-center">
        <div v-for="(item,i) in star" :key="i" class="md:p-1 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="box">
          <img :src="item.image" :alt="item.alt" class="">
          <div class="box-content">
            <h3 class="title">{{item.alt}}</h3>
            <span>Projects</span>
          </div>
        </div>
        </div>
      </div>
    </div>

    <!-- <div class="pt-10 md:flex md:flex-wrap lg:justify-center">
      <div v-for="(item, i) in project" :key="i" class="w-full md:p-1 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="box">
          <img :src="item.image" :alt="item.alt" class="" />
          <div class="box-content">
            <h3 class="title">{{ item.alt }}</h3>
            <span>Projects</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: "tab-1",
      starling_mall:[
        { image: "/images/projects/starling_1.jpg", alt: "Starling Mall" },
        { image: "/images/projects/starling_2.jpg", alt: "Starling Mall" },
        { image: "/images/projects/starling_3.jpg", alt: "Starling Mall" },
        { image: "/images/projects/starling_4.jpg", alt: "Starling Mall" },
        // { image: "/images/projects/starling_5.jpg", alt: "Starling Mall" },
      ],
      mont_kiara:[
        { image: "/images/projects/acoris-1.jpg", alt: "Acoris Mont Kiara" },
        { image: "/images/projects/acoris-2.jpg", alt: "Acoris Mont Kiara" },
        { image: "/images/projects/acoris-3.jpg", alt: "Acoris Mont Kiara" },
        { image: "/images/projects/acoris-4.jpg", alt: "Acoris Mont Kiara" },
      ],
      suria_klcc:[
        { image: "/images/projects/klcc-1.jpg", alt: "Suria KLCC" },
        { image: "/images/projects/klcc-2.jpg", alt: "Suria KLCC" },
        { image: "/images/projects/klcc-3.jpg", alt: "Suria KLCC" },
        { image: "/images/projects/klcc-4.jpg", alt: "Suria KLCC" },
        // { image: "/images/projects/klcc-5.jpg", alt: "Suria KLCC" },
      ],
      equatorial:[
        { image: "/images/projects/ijm-1.jpg", alt: "Equatorial Hotel" },
        { image: "/images/projects/ijm-2.jpg", alt: "Equatorial Hotel" },
        { image: "/images/projects/ijm-3.jpg", alt: "Equatorial Hotel" },
        { image: "/images/projects/ijm-4.jpg", alt: "Equatorial Hotel" },
        // { image: "/images/projects/ijm-5.jpg", alt: "Equatorial Hotel" },
      ],
      star: [
        { image: "/images/projects/star-1.jpg", alt: "Star Residence" },
        { image: "/images/projects/star-2.jpg", alt: "Star Residence" },
        { image: "/images/projects/star-3.jpg", alt: "Star Residence" },
      ],
    };
  },
};
</script>

<style>
/* project hover */
.box {
  background: #b91c1c;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.box:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}
.box img {
  width: 100%;
  height: auto;
  transform: translate3d(-15px, 0, 0) scale(1.12);
  transition: all 0.35s ease 0s;
}
.box:hover img {
  opacity: 0.3;
  transform: translate3d(0, 0, 0) scale(1);
}
.box .box-content {
  color: #fff;
  text-align: right;
  padding: 0 10px;
  border-right: 2px solid #fff;
  opacity: 0;
  position: absolute;
  top: 20px;
  left: -100%;
  transition: all 0.35s ease 0s;
}
.box:hover .box-content {
  opacity: 1;
  left: 10px;
}
.box .title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}
.box .post {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  text-transform: capitalize;
  letter-spacing: 1px;
  display: block;
}

@keyframes animate-hover {
  0% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
}
@media only screen and (max-width: 990px) {
  .box {
    margin: 0 0 10px;
  }
}
/* end */
</style>